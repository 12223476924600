import React from 'react';
import { ParagraphImageFragmentFragment } from '../../../generated/types';
import { Box, Img } from '@chakra-ui/react';
import Wrapper from '../Wrapper';
import Container from '../../Layout/Container';

export interface ImageProps {
  data: ParagraphImageFragmentFragment;
  isInArticleLayout?: boolean;
}

const Image: React.FC<ImageProps> = ({ data, isInArticleLayout }) => {
  if (!data.image) {
    return null;
  }

  const imageUrl =
    data.viewmode === 'original'
      ? data.image.original.url
      : data.image.teaser.url;

  const title =
    data.image?.name &&
    data.image?.useNameAsCaption &&
    (!data.caption || data.caption === '')
      ? data.image.name
      : data.caption || '';

  const copyright = data.image?.copyright;

  const Inner = (
    <Box pos="relative">
      <Img
        width="100%"
        htmlWidth="815px"
        htmlHeight="408px"
        src={imageUrl}
        borderRadius={{
          base: 0,
          md: 'lg',
        }}
      />
      {title && (
        <Box mt={1} fontWeight="bold">
          {title}
        </Box>
      )}
      {copyright && (
        <Box fontSize="xs" color="gray.600">
          © {copyright}
        </Box>
      )}
    </Box>
  );

  return (
    <Wrapper className="ParagraphImage">
      {isInArticleLayout ? (
        Inner
      ) : (
        <Container className="ParagraphContainer">{Inner}</Container>
      )}
    </Wrapper>
  );
};

export default Image;
